import React from "react";

import PageLayout from "siteLayouts/Page";

import { ContainerMaxWidth, Panel, PanelSection } from "siteComponentsLibrary/Layout";
import { ExternalLink } from "siteComponentsLibrary/Buttons";
import { EmailLink } from "siteComponentsLibrary/Display";

import ProcessingPurposes from "./ProcessingPurposes";

const assets = require("siteAssets/pageManager").default("privacy-policy");

const PrivacyPolicyView = (props) => {
	return (
		<PageLayout pageTitle={assets.pageTitle} headingText={assets.headingText}>
			
			<ContainerMaxWidth>
				<Panel header={assets.panelHeader}>
					
					<PanelSection className="panel-header" heading="How to contact us">
						<div>
							For the purposes of relevant data protection legislation, we are a
							controller of your personal data. As a controller, we use (or
							process) the personal data we hold about you in accordance with this
							privacy notice.
						</div>
						<div>
							Data Controller: Head of Compliance, Freedom Brokers Ltd, BizSpace
							Cheadle, Cheadle Place, Stockport Road, Cheadle, Cheshire SK8 2JX.
						</div>
						<div>
							Contact for queries: Compliance Team via email at{" "}
							<EmailLink emailAddress={"compliance@freedombrokers.co.uk"} nowrap={false} />.
						</div>
					</PanelSection>
				
					<PanelSection className="panel-header" heading="Your Information">
						<div>
							This privacy notice relates to personal information that identifies
							you as a natural person (whether you are an actual or potential
							customer, an individual who browses our website or an individual
							outside our organisation with whom we interact). We refer to this
							information throughout this privacy notice as personal data or
							personal information and further detail of what this includes are
							set out in this privacy notice below.
						</div>
						<div>
							The privacy and security of your personal information is very
							important to us so we want to assure you that your information will
							be properly managed and protected by us at all times. Please read
							this privacy notice carefully as it explains how we may collect and
							use your personal data.
						</div>
					</PanelSection>

					<PanelSection
						heading="What type of personal information do we need?"
					>
						<ul>
							<li>
								We may need personal details which will include contact and dates
								of birth, financial details, and Insurance details, such as your
								vehicle and driving history including claims.
							</li>
							<li>
								For Telematics insurance contracts we need personal details
								relating to time, location and driving, including; time, latitude,
								longitude, speed, duration, distance and acceleration. We will
								collect event data such as accident detection, unit tampering or
								activation and deactivation events.
							</li>

							<li>
								From the Freedom To Drive Smartphone App, which is required for
								telematics policies we may collect technical data and related
								information, including but not limited to technical information
								about your smartphone device, system and application software, and
								peripherals. In addition, where your policy requires a Bluetooth
								telematics device, we’ll require access to your Bluetooth status
								and background location services as defined by the respective
								Apple, Google and Huawei App stores.
							</li>

							<li>
								We will only collect what is necessary and will only keep it for
								as long as we are required to do in line with our data retention
								policy.
							</li>
						</ul>
					</PanelSection>

					<PanelSection
						heading="What other types of information do we need?"
					>
						<ul>
							<li>
								Under certain circumstances we may also need to obtain information
								about Health, Injuries or Criminal Convictions, your claims or
								credit history.
							</li>
							<li>
								By asking us to arrange a contract of insurance for you where this
								involves passing information to us relating to children, you
								confirm to us that in doing so you are the responsible guardian of
								the child.
							</li>
							<li>
								We also collect information from publicly available sources and
								third party databases made available to the insurance industry for
								the purposes of reducing fraud and financial crime as well as any
								other third party databases where your personal data may be held,
								provided such third parties have lawful bases on which to share
								such personal data with us.
								<br/>
								We will only collect what is necessary and protect it with
								appropriate security measures.
							</li>
						</ul>
					</PanelSection>

					<PanelSection
						heading="How will we use the information you give us?"
					>
						<div>
							We will use your information to provide you with a quotation at your
							request, on the basis that it is necessary to administer your
							insurance contract , for fraud prevention and detection, to
							undertake market research, product development, for statistical
							analysis for behavioural analysis and to develop and improve the
							services which we offer, or help you make a claim.
						</div>
						<div>
							In addition, for telematics policies, we will use your information
							to arrange the installation of your device through our third-party
							supplier, to assess your driving behaviour, to provide trip
							information on the Freedom To Drive App, assist you in the case of
							an accident or for telematics research and analysis.
						</div>
						<div>
							We may rely on one or more of the following legal bases when
							processing your personal data for the following purposes:
						</div>

						<ProcessingPurposes />
					</PanelSection>

					<PanelSection
						heading="Special categories of data and criminal convictions"
					>
						<div>
							We may also need to collect special categories of data from you such
							as information about your health, personal data revealing racial or
							ethnic origin, religious or philosophical beliefs or trade union
							membership and generic data. We may also collect information on
							criminal convictions which we may share with third parties. The
							lawful basis on which we can do this is the processing is necessary
							for reasons of substantial public interest relating to insurance
							purposes.
						</div>
						<div>
							Insurance purposes mean (a) advising on, arranging, underwriting or
							administering an insurance contract (b) administering a claim under
							an insurance contract or (c) exercising a right or complying with an
							obligation, arising in connection with an insurance contract,
							including a right or obligation arising under an enactment or a rule
							of law.
						</div>
					</PanelSection>

					<PanelSection
						heading="How we contact you about other products and services"
					>
						<div>
							We may from time to time process your personal data to let you know
							about similar products and services that may be of interest to you.
							This is because we value your custom and we pride ourselves in
							offering professional and tailored advice which meets your specific
							insurance needs. This includes keeping you informed on the latest
							insurance and industry information and details of any offers or
							promotions relating to the insurance services we provide to you. Our
							lawful basis for processing your personal data in this way is as is
							necessary to pursue the legitimate interests of our business, unless
							we have otherwise obtained your consent to do so. We may contact you
							by post, telephone or e-mail. You will be given the option to stop
							receiving any communications from us in this regard at any time
							however please note that this will not affect us contacting you
							about the servicing of products that you have specifically requested
							from us.
						</div>
					</PanelSection>

					<PanelSection
						heading="Who we pass your personal data to"
					>
						<div>
							We may need to pass your personal data to other companies which may
							include:
						</div>
						<ul>
							<li>
								Other companies or brands within our group of companies, for
								example if we are unable to provide a suitable insurance policy on
								request or at renewal we will check if any of our associated group
								companies can provide you with suitable cover{" "}
							</li>
							<li>
								The insurers, intermediaries and third-party service providers
								that we use for the purpose of arranging and administering your
								insurance policy. This may also include risk management assessors,
								uninsured loss recovery agencies, premium finance providers and
								other third parties involved (directly or indirectly) in the
								administration of your insurance and its associated benefits{" "}
							</li>
							<li>
								Firms that provide administration and processing services to us or
								on our behalf under contract in order to complete activities such
								as claims handling, IT systems and administrative services and
								other activities set out in this privacy notice, as well as
								support activities such as finance and auditing services
							</li>
							<li>
								Organisations that have a specific role laid out in law, such as
								statutory bodies, regulatory authorities and other authorised
								bodies
							</li>
							<li>
								Other organisations where we have a duty to or are permitted to
								disclose your personal information by law, for example if we
								received a valid request from the police or other third-party
								organisation in the interest of preventing and detecting crime
							</li>
							<li>
								Fraud prevention agencies and operators of registers available to
								the insurance industry to check information and prevent fraud
							</li>
							<li>
								Credit reference agencies to check your credit history. This check
								will be recorded on your credit reference file without affecting
								your ability to apply for credit or other financial products
							</li>
							<li>
								Third parties we use to recover money you may owe us or to whom we
								may sell your debt
							</li>
							<li>
								Another company, if our business or part of it is bought or taken
								over by that company to make sure your insurance policy can
								continue to be serviced or as part of preliminary discussions with
								that company about a possible sale or take over{" "}
							</li>
							<li>
								We enlist the services of{" "}
								<ExternalLink
									href="https://mycarneedsa.com/"
									label="MyCarNeedsA.com (MCNA)"
								/>{" "}
								who provide you with MOT reminders at the appropriate time to help
								ensure your vehicle remains legally on the road. MCNA will provide
								MOT quotes from local garages to facilitate your MOT when it is
								due. The MCNA network of garages are DVSA MOT approved and
								consistently monitored for quality and service levels.
							</li>
						</ul>
						<div>
							The information you share with us may be transferred by us or any of
							the types of firms or organisations we have noted above, to other
							countries in order for processing to take place, including locations
							outside of the UK and the European Union. We will only do so if
							there are adequate levels of protection in place as required by
							applicable data protection laws.
						</div>
					</PanelSection>

					<PanelSection
						heading="How do we obtain your information?"
					>
						<div>
							We may gather it from information you provide, by telephone, face to
							face, online, by email, the telematics unit in your car, from the
							Freedom To Drive Smartphone App, or from surveys or feedback you
							provide.
						</div>
						<div>
							We may receive it from Insurers, other insurance brokers, DVLA,
							industry wide databases, external third-party suppliers working on
							our behalf, finance providers, price comparison sites and firms
							handling claims.
						</div>
					</PanelSection>

					<PanelSection className="panel-header" heading="What are my legal rights?">
						<div>
							We will only store your data for as long as is necessary to comply
							with the requirements of your insurance contract(s) and any legal
							obligations or lawful processing conditions that may exist as a
							result. You have a number of rights concerning the personal
							information we use, which you may ask us to observe. In some cases
							even when you make a request concerning your personal information,
							we may not be required, or be able to carry out your request as this
							may result in us not being able to fulfil our legal and regulatory
							obligations under the lawful processing conditions under which we
							hold your data or because there is a minimum statutory period of
							time for which we have to keep you information. If this is the case,
							we’ll let you know our reasons.
						</div>
						<div>You can ask us to:</div>
						<ul>
							<li>Provide a copy of your personal information</li>
							<li>
								Correct or delete unnecessary or inaccurate personal information
							</li>
							<li>
								Restrict or to object to the use of your personal information at
								any time
							</li>
							<li>
								Object to any automated decision, including profiling which may
								have been used by insurers when underwriting your quotation. Where
								an automated decision has been made we will advise you of this and
								of your rights
							</li>
							<li>
								Provide your personal data in a structured, commonly used and
								machine-readable format and to have your personal data transferred
								to another controller. This right only applies where our
								processing of your personal data is automated and the processing
								took place initially with your consent or for the performance of a
								contract with you
							</li>
							<li>
								Where we rely on your consent to use your personal information,
								you can withdraw that consent at any time. Where your consent is
								withdrawn, your previous consent will remain valid in respect of
								our use of your information prior to the date you withdrew it, or
								if any marketing material has been sent prior to you advising that
								you don’t want us to contact you again.
							</li>
						</ul>
					</PanelSection>

					<PanelSection>
						<div>
							If you have any questions or concerns about this privacy notice or
							your data protection rights, please contact us using our details set
							out at the beginning of this privacy notice.
						</div>
						<div>
							You also have the right to make an enquiry or to complain to the
							Information Commissioner's Office (ICO) if you are unhappy with our
							use of your data, or if you think we have breached a legal
							requirement. Further details about the ICO are available at:{" "}
							<ExternalLink href="https://ico.org.uk/" label="www.ico.org.uk"/>.
						</div>
					</PanelSection>

					<PanelSection>
						<div className="alert alert-success">
							This privacy notice may vary from time to time so please check it regularly.
						</div>
					</PanelSection>
				</Panel>
			</ContainerMaxWidth>

		</PageLayout>
	);
};

export default PrivacyPolicyView;
