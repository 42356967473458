import React from "react";

import { OpexBreakdownTable, TickList } from "siteComponentsLibrary/Display";

const DocsBaseUrl = require("config").default.links.docs.baseUrl;
const DocsPreviousBaseUrl = require("config").default.links.docs.replacedUrl;

export const opexData = {
	["LEGAL"]: {
		groupCode: "LEGAL",
		heading: "Legal Expenses",
		route: "/legal-expenses",
		
		summaryCard: {
			layoutClass: "col-md-6 opex-layout-1",
			imageOverlay: true,
			imgSrc: require("siteAssets/images/opex/opex-legal.png"),
			summaryText: (
				<span>
					Covers you or a named driver against the costs of legal fees and
					expenses up to <span className="money-value">100,000</span>
				</span>
			),
		},
		
		policyTypes: ["PC", "GV"],

		offlinePurchase: false,
		onlinePurchase: true,
		annualPrice: "29.95",
		// monthlyPrice: "2.50",
		pageHeading: "What is Legal Expenses cover?",
		description: (
			<>
				<p>
					Our legal expenses product covers you or a named driver against the
					costs of legal fees and expenses up to{" "}
					<span className="money-value">100,000</span> and also provides
					additional cover for:
				</p>
				<TickList
					className="gap-3 mb-4"
					listData={[
						<span>
							Your opponents' legal costs if the proceedings are unsuccessful.
						</span>,
						<span>
							Legal expenses incurred on your behalf by your solicitor if
							proceedings or negotiations are unsuccessful.
						</span>,
						<span>
							Disbursements incurred on your behalf in pursuit of your claim,
							e.g. court fees or expert report fees such as for engineer
							reports. If the case fails, the policy will pay these to your
							solicitor.
						</span>,
					]}
				/>
				<p className="alert alert-success small">
					What this means is, if a claim was to go to court and the proceedings
					were unsuccessful, legal expenses cover could protect you from ever
					having to pay the fees yourself.
				</p>
			</>
		),
		documents: [
			{
				ipidUrl: DocsBaseUrl + "IPID-LegalProtection.pdf",
				policyWordingUrl: DocsBaseUrl + "PW-LegalProtection.pdf",
			},
			//{
			//	endDate: "06-05-2021",
			//	ipidUrl: DocsPreviousBaseUrl + "IPID-LegalProtection-20210506.pdf",
			//	policyWordingUrl:
			//		DocsPreviousBaseUrl + "PW-LegalProtection-20210506.pdf",
			//},
		],
		claimNumbers: ["020 8343 3242"],
	},

	["BD"]: {
		groupCode: "BD",
		heading: "UK Breakdown Cover",
		route: "/breakdown-cover",

		summaryCard: {
			layoutClass: "col-md-6 opex-layout-1",
			imageOverlay: true,
			imgSrc: require("siteAssets/images/opex/opex-breakdown.png"),
			summaryText: "Repair your vehicle if you're stuck on the roadside",
		},
		
		policyTypes: ["PC", "GV"],

		offlinePurchase: false,
		onlinePurchase: true,
		priceText: "From",
		annualPrice: "49.95",
		// monthlyPrice: "4.16",
		pageHeading: "Why take out UK Breakdown Cover?",
		description: (
			<>
				<p>
					With our UK Breakdown Cover you can be assured we'll organise the best
					people for the job if you're stuck on the roadside.
				</p>
				<p>
					We can also include vehicle recovery, so even if our experts can't fix
					your vehicle on the roadside, you won't be stuck and with our UK Full
					Breakdown cover, doorstep assistance is included so our repairers will
					come to your home address and make sure you can get back on the road.
				</p>

				<div className="row">
					<div className="col-12 col-xl-12">
						<OpexBreakdownTable
							coverLevels={[
								{
									id: "BK1",
									name: "UK Local Roadside",
									price: 49.95,
								},
								{
									id: "BK2",
									name: "UK National Roadside",
									price: 59.95,
								},
								{
									id: "BK3",
									name: "UK Full Breakdown",
									price: 69.95,
								},
							]}
							benefitsData={[
								{
									id: "bdra",
									name: "Roadside Assistance",
									levelIncludes: [
										{ coverLevelId: "BK1", included: true },
										{ coverLevelId: "BK2", included: true },
										{ coverLevelId: "BK3", included: true },
									],
									info: "If the vehicle breaks down more than one mile from the home address, we will arrange service at the scene of the breakdown for up to 1 hour and pay call-out fees and labour costs in an attempt to repair it.",
								},
								{
									id: "bdlr",
									name: "Local Recovery",
									levelIncludes: [
										{ coverLevelId: "BK1", included: true },
										{ coverLevelId: "BK2", included: true },
										{ coverLevelId: "BK3", included: true },
									],
									info: "If the vehicle breaks down more than one mile from the home address and it cannot be repaired at the scene of the breakdown, we will arrange and pay the cost of taking it, the driver and the passenger(s) from the scene of the breakdown to the nearest repairer, up to a maximum of a 10 mile round trip.",
								},
								{
									id: "bdfr",
									name: "Full Recovery",
									levelIncludes: [
										{ coverLevelId: "BK1", included: false },
										{ coverLevelId: "BK2", included: true },
										{ coverLevelId: "BK3", included: true },
									],
									info: "If the vehicle breaks down more than one mile from the home address and it cannot be repaired at the scene of the breakdown or the nearest repairer the same day, we will arrange and pay the cost of taking it, the driver and the passenger(s) from the scene of the breakdown to your home, your original destination or the nearest repairer.",
								},
								{
									id: "bdoa",
									name: "Onward Travel",
									levelIncludes: [
										{ coverLevelId: "BK1", included: false },
										{ coverLevelId: "BK2", included: true },
										{ coverLevelId: "BK3", included: true },
									],
									info: "If the vehicle breaks down and it cannot be repaired the same day, and it cannot be recovered to any one place you choose, we will pay the cost of alternative travel arrangements for the driver and the passenger(s).",
								},
								{
									id: "bdoa",
									name: "Alternative accommodation",
									levelIncludes: [
										{ coverLevelId: "BK1", included: false },
										{ coverLevelId: "BK2", included: true },
										{ coverLevelId: "BK3", included: true },
									],
									info: "If the vehicle breaks down more than 25 miles from the home address, and it cannot be repaired the same day, and it cannot be recovered to any one place you choose, we will pay the cost of overnight accommodation for the driver and the passenger(s).",
								},
								{
									id: "bdha",
									name: "Doorstep Assistance",
									levelIncludes: [
										{ coverLevelId: "BK1", included: false },
										{ coverLevelId: "BK2", included: false },
										{ coverLevelId: "BK3", included: true },
									],
									info: "If the vehicle breaks down at the home address or within one mile of the home address, we will arrange service at the scene of the breakdown for up to 1 hour and pay call-out fees and labour costs in an attempt to repair it. If it cannot be repaired at the scene of the breakdown, we will arrange and pay the cost of taking it, the driver and the passenger(s) from the scene of the breakdown to the nearest repairer, up to a maximum of a 10 mile round trip.",
								},
							]}
							documents={[
								{
									ipids: [
										DocsBaseUrl + "IPID-Breakdown-UKLocal.pdf",
										DocsBaseUrl + "IPID-Breakdown-UKNational.pdf",
										DocsBaseUrl + "IPID-Breakdown-UKNational.pdf",
									],
									policyWordings: [
										DocsBaseUrl + "PW-Breakdown-UKLocal.pdf",
										DocsBaseUrl + "PW-Breakdown-UKNational.pdf",
										DocsBaseUrl + "PW-Breakdown-UKNational.pdf",
									],
								},
								//{
								//	endDateText: "was purchased",
								//	endDate: "04-05-2022",
								//	ipids: [
								//		DocsPreviousBaseUrl +
								//			"IPID-Breakdown-UKRoadside-20220503.pdf",
								//		DocsPreviousBaseUrl +
								//			"IPID-Breakdown-UKRoadsideRecovery-20220503.pdf",
								//		DocsPreviousBaseUrl + "IPID-Breakdown-UKTotal-20220503.pdf",
								//	],
								//	policyWordings: [
								//		DocsPreviousBaseUrl +
								//			"PW-Breakdown-UKRoadside-20220503.pdf",
								//		DocsPreviousBaseUrl +
								//			"PW-Breakdown-UKRoadsideRecovery-20220503.pdf",
								//		DocsPreviousBaseUrl + "PW-Breakdown-UKTotal-20220503.pdf",
								//	],
								//},
							]}
						/>
					</div>
				</div>
			</>
		),
		claimNumbers: ["01206 812 842"],
		//previousClaimNumbers: {
		//	endDate: "04-05-2022",
		//	claimNumbers: ["01245 408492", "0330 123 0724"],
		//},
		claimDetails: (
			<>
				<p>Please ensure that you have the following information available:</p>
				<TickList
					className="gap-2"
					listData={[
						"The document number and the person named as 'the insured' in the policy schedule.",
						"The phone number you are calling from.",
						"The vehicle details such as registration number, make, model, colour, transmission type etc.",
						"The location of the broken down vehicle, including road numbers or names and landmarks.",
						"Any vehicle modifications which may be relevant to the recovery of the vehicle.",
					]}
				/>
			</>
		),
	},
	
	["KEY"]: {
		groupCode: "KEY",
		heading: "Key Cover",
		route: "/key-cover",

		summaryCard: {
			layoutClass: "col-md-6 col-lg-3 opex-layout-2",
			imageOverlay: true,
			imgSrc: require("siteAssets/images/opex/opex-key-cover.png"),
			summaryText: "If your key is accidentally lost, stolen or accidentally damaged",
		},
		
		policyTypes: ["PC", "GV"],

		offlinePurchase: true,
		onlinePurchase: true,
		annualPrice: "19.95",
		// monthlyPrice: "1.66",
		pageHeading: "What is Key Cover?",
		description: (
			<>
				<p>
					This policy covers you if an insured key is accidentally lost, stolen
					or accidentally damaged.
				</p>
				<TickList
					className="gap-3 mb-4"
					listData={[
						<span>
							Locksmith charges if your insured keys are lost, stolen, damaged
							or locked in your home, motor cycle or motor vehicle
						</span>,
						<span>
							New locks if there is a security risk to your motor cycle, motor
							vehicle or home due to the loss or theft of your insured key
						</span>,
						<span>
							Replacement keys if your insured keys are stolen, deemed
							permanently lost, or broken
						</span>,
						<span>
							Onward transport costs up to{" "}
							<span className="money-value">75</span> per day if you have no
							access to your motor cycle or motor vehicle while away from your
							home due to lost, stolen or broken keys
						</span>,
						<span>
							Vehicle hire costs up to <span className="money-value">40</span>{" "}
							per day for up to three days if you are unable to use your motor
							cycle or motor vehicle while away from your home due to lost,
							stolen or broken keys
						</span>,
						<span>
							Accommodation costs up to <span className="money-value">120</span>{" "}
							if you have no access to your home
						</span>,
						<span>
							We will pay a <span className="money-value">20</span> reward to
							the finder of your lost insured keys
						</span>,
					]}
				/>
			</>
		),
		documents: [
			{
				ipidUrl: DocsBaseUrl + "IPID-KeyCover-FIGL.pdf",
				policyWordingUrl: DocsBaseUrl + "PW-KeyCover-FIGL.pdf",
			},
			{
				endDateText: "was purchased",
				endDate: "14-11-2023",
				ipidUrl: DocsPreviousBaseUrl + "IPID-KeyCover-20231114.pdf",
				policyWordingUrl: DocsPreviousBaseUrl + "PW-KeyCover-20231114.pdf",
			},
		],
		claimNumbers: ["0345 528 0255"],
		previousClaimNumbers: {
			endDate: "14-11-2023",
			claimNumbers: ["0203 794 9300", "0203 794 9309"],
		},
	},

	["XS"]: {
		groupCode: "XS",
		heading: "Excess Protection",
		route: "/excess-protection",

		summaryCard: {
			layoutClass: "col-md-6 col-lg-3 opex-layout-2",
			imageOverlay: true,
			imgSrc: require("siteAssets/images/opex/opex-excess-protection.png"),
			summaryText: "Protect against the high costs of making a claim",
		},
		
		policyTypes: ["PC", "GV"],

		offlinePurchase: false,
		onlinePurchase: true,
		priceText: "From",
		annualPrice: "34.95",
		// monthlyPrice: "2.92",
		pageHeading: "Why take out Excess Protection?",
		description: (
			<>
				<p>
					The excess on your policy is the amount you will have to contribute
					should you need to claim. Sometimes this cost can discourage you from
					using your policy, so many policy holders don't use the services that
					are available to them.
				</p>
				<p>
					If you're unfortunate enough, you might be hit by a one-off payment of
					around <span className="money-value">750</span>. This would need to be
					paid before you can receive your vehicle back from the repairer, and
					could further delay you getting back on the road.
				</p>
				<p>
					If you have Excess Protection, we've got you covered against the high
					costs of making a claim. If you should ever need your vehicle repaired
					or replaced because of accidental damage, fire, theft or vandalism
					that wasn't your fault, our Excess Protection will allow you to
					recover this cost.
				</p>
			</>
		),
		documents: [
			{
				ipidUrl: DocsBaseUrl + "IPID-ExcessProtection-FIGL.pdf",
				policyWordingUrl: DocsBaseUrl + "PW-ExcessProtection-FIGL.pdf",
			},
			{
				endDateText: "was purchased",
				endDate: "30-10-2023",
				ipidUrl: DocsPreviousBaseUrl + "IPID-ExcessProtection-20231030.pdf",
				policyWordingUrl:
					DocsPreviousBaseUrl + "PW-ExcessProtection-20231030.pdf",
			},
		],
		claimNumbers: ["0345 528 0255"],
		previousClaimNumbers: {
			endDate: "30-10-2023",
			claimNumbers: ["0344 856 2359"],
		},
	},

	["LD"]: {
		groupCode: "LD",
		heading: "Motor Prosecution Defence",
		route: "/motor-prosecution-defence",

		summaryCard: {
			layoutClass: "col-md-6 col-lg-3 opex-layout-2",
			imageOverlay: true,
			imgSrc: require("siteAssets/images/opex/opex-motor-prosecution.png"),
			summaryText: (
				<span>
				Provide cover for up to <span className="money-value">100,000</span> for
				advisers' costs for certain types of legal action
			</span>
			),
		},
		
		policyTypes: ["PC", "GV"],

		offlinePurchase: true,
		onlinePurchase: true,
		annualPrice: "24.95",
		// monthlyPrice: "1.66",
		pageHeading: "Why take out Motor Prosecution Defence?",
		description: (
			<>
				{/* <p>
					This policy provides cover for up to{" "}
					<span className="money-value">25,000</span> costs for legal action in
					the event of a motoring offence arising from your use of the vehicle.
				</p> */}
				<p>
					We'll cover a legal advisers' costs up to{" "}
					<span className="money-value">25,000</span> to help you defend a claim
					in the following situations:
				</p>
				<ul>
					<li>
						Motor Prosecution Defence: Advisers' costs to represent you in a
						legal action in respect of a motoring offence, arising from your use
						of the motor vehicle driven by you.
					</li>
					<li>
						This is extended to include a caravan or trailer whilst attached to
						the vehicle.
					</li>
				</ul>
			</>
		),
		documents: [
			{
				ipidUrl: DocsBaseUrl + "IPID-LicenceDefence.pdf",
				policyWordingUrl: DocsBaseUrl + "PW-LicenceDefence.pdf",
			},
		],
		claimNumbers: ["0330 024 1727"],
	},

	["RPL"]: {
		groupCode: "RPL",
		heading: "Replacement Vehicle",
		route: "/replacement-hire-vehicle",

		summaryCard: {
			layoutClass: "col-md-6 col-lg-3 opex-layout-2",
			imageOverlay: true,
			imgSrc: require("siteAssets/images/opex/opex-replacement-car.png"),
			summaryText: "In the event your vehicle is rendered un-driveable or is being repaired",
		},
		
		policyTypes: ["PC", "GV"],

		offlinePurchase: false,
		onlinePurchase: true,
		priceText: "From",
		annualPrice: "34.95",
		// monthlyPrice: "2.92",
		pageHeading: "Why buy Replacement Hire Vehicle?",
		description: (
			<>
				<p>
					Unlike many insurers, we're able to provide Replacement Hire Vehicle
					in the event your vehicle is rendered un-driveable or is being
					repaired following a road traffic accident, fire, malicious damage or
					theft or attempted theft.
				</p>
				<p>
					For many people, their vehicle is the hub of their business, and
					without it they may be unable to continue working. With many insurance
					policies it is up to the insurer to decide whether you need a
					replacement vehicle or not. We think you should be given the chance to
					decide yourself.
				</p>
				<p>
					We can minimise any potential loss of income by providing you a hire
					vehicle for up to 14, 21 or 28 days.
				</p>
			</>
		),
		documents: [
			{
				ipidUrl: DocsBaseUrl + "IPID-ReplacementVehicle.pdf",
				policyWordingUrl: DocsBaseUrl + "PW-ReplacementVehicle.pdf",
			},
		],
		claimNumbers: ["0344 856 2359"],
	},

	["SCRATCH"]: {
		groupCode: "SCRATCH",
		heading: "Scratch, Dent & Alloy Protection",
		route: "/scratch-dent-alloy",

		summaryCard: {
			layoutClass: "col-md-6 col-lg-4 opex-layout-1",
			imageOverlay: true,
			imgSrc: require("siteAssets/images/opex/opex-scratch-dent-alloy.png"),
			summaryText:  (
				<span>
				This is a <strong>S</strong>mall to <strong>M</strong>edium{" "}
					<strong>A</strong>rea <strong>R</strong>epair <strong>T</strong>
				echnology (SMART) repair and alloy wheel insurance
			</span>
			),
		},
		
		policyTypes: ["PC"],

		annualPrice: "119.00",
		// monthlyPrice: "13.37",
		offlinePurchase: true,
		onlinePurchase: true,
		pageHeading: "What is Scratch, Dent & Alloy Protection?",
		description: (
			<>
				<p>
					This is a <strong>S</strong>mall to <strong>M</strong>edium{" "}
					<strong>A</strong>rea <strong>R</strong>epair <strong>T</strong>
					echnology (SMART) repair and alloy wheel insurance, covering minor
					cosmetic damage to the bodywork of your vehicle as a result of day to
					day motoring, and accidental and malicious damage to the alloy wheels.
				</p>
			</>
		),
		documents: [
			{
				ipidUrl: DocsBaseUrl + "IPID-ScratchDentAlloy.pdf",
				policyWordingUrl: DocsBaseUrl + "PW-ScratchDentAlloy.pdf",
			},
		],
		claimNumbers: ["0344 856 2117"],
		claimUrl: "https://scratchanddent.davies-group.com",
		claimUrlText: "Claim online",
	},
	
	["TOOLS"]: {
		groupCode: "TOOLS",
		heading: "Tools In Transit",
		route: "/tools-in-transit",

		summaryCard: {
			layoutClass: "col-md-6 col-lg-4 opex-layout-1",
			imageOverlay: true,
			imgSrc: require("siteAssets/images/opex/opex-tools.png"),
			summaryText:  (
				<span>
					<span className="d-block mb-2">
						Protection if your work tools are lost, stolen or need replacement
					</span>
					<span>
						<strong>Available for our van insurance customers</strong>
					</span>
				</span>
			),
		},
		
		policyTypes: ["GV"],

		offlinePurchase: false,
		onlinePurchase: true,
		priceText: "From",
		annualPrice: "59.95",
		// monthlyPrice: "4.16",
		pageHeading: "What is Tools in Transit cover?",
		description: (
			<>
				<p>
					They say a good workman is only as good as his tools. We know they're
					vital to your business, and if you're without them then you might be
					unable to work. This could mean expensive tool hire or delays to your
					customer's work being completed.
				</p>
				<p>
					In the event of an accident, not only could you face a damaged vehicle
					but your tools and equipment might also be lost, stolen or need
					replacement.
				</p>
				<p>
					You can select the amount of cover that meets your needs up to a limit
					of <span className="money-value">5,000</span>.
				</p>
				<p>
					If your equipment is vital to your business, our Tools in Transit
					cover will provide the cover you need to get you working again.
				</p>
			</>
		),
		documents: [
			{
				ipidUrl: DocsBaseUrl + "IPID-ToolsInTransit-FIGL.pdf",
				policyWordingUrl: DocsBaseUrl + "PW-ToolsInTransit-FIGL.pdf",
			},
			{
				endDateText: "was purchased",
				endDate: "14-11-2023",
				ipidUrl: DocsPreviousBaseUrl + "IPID-ToolsInTransit-20231114.pdf",
				policyWordingUrl:
					DocsPreviousBaseUrl + "PW-ToolsInTransit-20231114.pdf",
			},
		],
		claimNumbers: ["0345 528 0255"],
		previousClaimNumbers: {
			endDate: "14-11-2023",
			claimNumbers: ["0344 856 2275"],
		},
	},
	
	["GOODS"]: {
		groupCode: "GOODS",
		heading: "Goods In Transit",
		route: "/goods-in-transit",

		summaryCard: {
			layoutClass: "col-md-6 col-lg-4 opex-layout-1",
			imageOverlay: true,
			imgSrc: require("siteAssets/images/opex/opex-goods.png"),
			summaryText:  (
				<span>
					<span className="d-block mb-2">
						Protection for goods in transit
					</span>
					<span>
						<strong>Available for our van insurance customers</strong>
					</span>
				</span>
			),
		},
		
		policyTypes: ["GV"],

		priceText: "From",
		annualPrice: "95.00",
		// monthlyPrice: "7.92",
		offlinePurchase: true,
		onlinePurchase: false,
		pageHeading: "Why buy Goods in Transit cover?",
		description: (
			<>
				<p>
					We are able to offer Goods in Transit cover in the event that any loss
					or damage occurs to customer goods whilst in transit.
				</p>
				<p>
					This will also include cover for liability for consequential financial
					loss arising from damage to, accidental delay to or accidental
					mis-delivery of customer's goods in transit.
				</p>
			</>
		),
		documents: [
			{
				ipidUrl: DocsBaseUrl + "IPID-GoodsInTransit.pdf",
				policyWordingUrl: DocsBaseUrl + "PW-GoodsInTransit.pdf",
			},
		],
		claimNumbers: ["0208 269 7651"],
	},
	
};

const opexDataManager = (key) => {
	if (key === "ALL") {
		const dataArrayAll = Array.from(Object.values(opexData));
		return dataArrayAll;
	}

	if (key === "PC" || key === "GV") {
		const dataArray = Array.from(Object.values(opexData)).filter((data) =>
			data.policyTypes.includes(key)
		);

		return dataArray;
	}

	let foundData = opexData[key];

	if (!foundData) {
		const msg = `opexTypesManager -- can't find ${key}`;
		throw msg;
	}

	return foundData;
};

export default opexDataManager;
