import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { withIdleTimer } from "react-idle-timer";
import config from "config";
import moment from "moment";
import { generateLookupObject } from "@library/common/helpers/service";
import scrollToTop from "@library/common/components/base/functions/scrollToTop";

import Layout from "siteLayouts/SidePanelRight";

import { Button } from "siteComponentsLibrary/Buttons";

export const IdleTimer = withIdleTimer(({ children }) => <>{children}</>);

const constStates = {
	reset: "reset",
	start: "start",
	balanceLoading: "balanceLoading",
	balanceFailedUnmatchedRef: "balanceFailedUnmatchedRef",
	balanceFailedTechnicalError: "balanceFailedTechnicalError",
	balance: "balance",
	customErrorMessage: "customErrorMessage",
	paymentLauncher: "paymentLauncher",
};
import siteServices from "siteManagers/services";
const transmissionService = siteServices.vehicle.transmission;
const fueltypeService = siteServices.vehicle.fueltype;

import paymentLauncher from "./paymentLauncher";

import PaymentLandingForm from "./Form";
import PaymentLandingBalance from "./Balance";
import PaymentStepChange from "./StepChange";

import { ModalLoadingOpened } from "siteComponents";

const sampleCredentials = {
	WebReference: "89659-49816-85390",
	Postcode: "CF82 8AA",
	DateOfBirth: "31 JULY 1967",
};
const sampleBalance = {
	TransactionId: "4204927",
	Status: "SUCCESS",
	FoundBalance: "-13567",
	PolicyType: "GV",
	FoundVehicle: {
		VehicleType: "GoodsVehicle",
		Abs: true,
		BodyStyle: "Van",
		BodySubStyle: "7",
		Lhd: false,
		Doors: "3",
		Seats: "6",
		GrossWeight: 3.2,
		RadiusLimit: "250",
		IsFittedWithRacking: true,
		HasSignage: true,
		AnyHazardousGoods: false,
		Id: "175389893",
		AbiCode: "90315579",
		AbiProductionYears: "2016 - 2017",
		Alarm: "NONE",
		Purchased: "2017-07-17T00:00:00",
		Cc: "1968",
		Fuel: "D",
		Immob: "FACT",
		Manufacturer: "VOLKSWAGEN",
		ModelDescription: "TRANSPORTER T32 KOMBI HIGHLINE S 150 DSG",
		Owner: "Proposer",
		Value: "23870",
		Paid: "23870",
		Keeper: true,
		RegNumber: "RF17HBX",
		Tracker: "NONE",
		Transmission: "M",
		WhereKept: "Driveway",
		KeptPostcode: "CF82 8AA",
		YearOfManufacture: "2017",
		Modifications: [],
		ModificationInd: false,
		CurrentMileage: "10000",
	},
	FoundAddress: {
		AbodeType: "House",
		Usage: "Main",
		CountryCode: "GB",
		Postcode: "CF82 8AA",
		HouseNameOrNumber: "3",
		AddressLine1: "Pengam Road",
		AddressLine2: "Ystrad Mynach",
		AddressLine3: "HENGOED",
		AddressLine4: "Mid Glamorgan",
	},
	TokenisationId: "302374",
	AllowPartPayments: true,
	MinPayment: "4523",
};
const sampleBalanceZero = {
	TransactionId: "3711142",
	Status: "SUCCESS",
	FoundBalance: 0, // -6096,
	PolicyType: "GV",
	FoundVehicle: {
		VehicleType: "GoodsVehicle",
		Abs: true,
		BodyStyle: "Van",
		BodySubStyle: "7",
		Lhd: false,
		Doors: "3",
		Seats: "6",
		GrossWeight: 3.2,
		RadiusLimit: "250",
		IsFittedWithRacking: true,
		HasSignage: true,
		AnyHazardousGoods: false,
		Id: "175389893",
		AbiCode: "90315579",
		AbiProductionYears: "2016 - 2017",
		Alarm: "NONE",
		Purchased: "2017-07-17T00:00:00",
		Cc: "1968",
		Fuel: "D",
		Immob: "FACT",
		Manufacturer: "VOLKSWAGEN",
		ModelDescription: "TRANSPORTER T32 KOMBI HIGHLINE S 150 DSG",
		Owner: "Proposer",
		Value: "23870",
		Paid: "23870",
		Keeper: true,
		RegNumber: "RF17HBX",
		Tracker: "NONE",
		Transmission: "M",
		WhereKept: "Driveway",
		KeptPostcode: "CF82 8AA",
		YearOfManufacture: "2017",
		Modifications: [],
		ModificationInd: false,
		CurrentMileage: "10000",
	},
	TokenisationId: "302374",
};

const PaymentLanding = (props) => {
	const {
		errorMessage,
		panelHeader,
		layoutBreakpoint,

		urlPaymentSuccess,
		urlBack,
		urlPaymentError,
		errorMessageFailedLookup,
		defaultWebReference,
		defaultSrc,
		idleTimeout = 1000 * 60 * 30,
	} = props;

	const urlOptionalRedirects = Object.fromEntries(
		[
			"urlRefused",
			"urlTimeout",
			"urlReferred",
			"urlDeclined",
			"urlTechnicalError",
		]
			.map((_key) => (_key in props ? [_key, props[_key]] : undefined))
			.filter((x) => x)
	);

	const { pathname } = useLocation();

	const [currentState, setCurrentState] = useState(constStates.start);
	const [lookups, setLookups] = useState(undefined);

	const [curCredentials, setCurCredentials] = useState(undefined);
	const [curBalance, setCurBalance] = useState(undefined);

	// const idleTimer = useIdleTimer({
	// 	timeout: idleTimeout,
	// 	onIdle: () => resetPage(),
	// });

	// ** DEV MODE **
	if (false) {
		useState(() => {
			setCurrentState(constStates.balance);
			setCurCredentials(sampleCredentials);
			setCurBalance(sampleBalance);
		}, []);
	}

	const resetPage = () => {
		setCurrentState(constStates.reset); // force an unload of the child component

		setCurrentState(constStates.start);
		setCurCredentials(undefined);
		setCurBalance(undefined);
		setPaymentAmount(undefined);
		console.log("DEBUG", "**************************************");
		console.log("DEBUG", "Page reset");
		console.log("DEBUG", "**************************************");
	};

	useEffect(() => {
		generateLookupObject([
			{ key: "fueltype", service: fueltypeService },
			{ key: "transmission", service: transmissionService },
		]).then((response) => setLookups(response));
	}, []);

	// check reset for browsers that remember the start state.
	// useEffect(() => {
	// 	if (pathname === urlBack) resetPage();
	// }, [pathname]); //

	useEffect(() => {
		if (currentState === constStates.reset) return;

		scrollToTop();
	}, [currentState]);

	const fnGotoPayment = (paymentAmount) => {
		setCurrentState(constStates.paymentLauncher);

		return paymentLauncher({
			balance: curBalance,
			credentials: curCredentials,
			amount: paymentAmount, // -1 * curBalance.FoundBalance,
			urlPaymentSuccess,
			urlBack,
			urlPaymentError,
			sourceOfEnquiry: defaultSrc,
			...urlOptionalRedirects,
		})
			.then((response) => {
				if (response?.Status !== "SUCCESS") {
					return false;
				}
				window.location.href = response?.RedirectUrl;
				return true;
			})
			.catch((err) => {
				return false;
			});
	};

	const onValueChange = (newValues) => {
		if (!newValues) return;

		setCurrentState(constStates.balanceLoading);
		setCurCredentials(newValues);

		//get balance
		siteServices.payment
			.GetPolicyBalance({
				WebReference: newValues.WebReference,
				DateOfBirth: moment(newValues.DateOfBirth).format("YYYY-MM-DD"),
				Postcode: newValues.Postcode,
			})
			.then((response = {}) => {
				const { Status } = response;

				// ** SUCCESS **
				if (Status === "SUCCESS") {
					setCurBalance(response);
					setCurrentState(constStates.balance);
					return;
				}

				if (Status === "ZERO_BALANCE") {
					setCurBalance(response);
					setCurrentState(constStates.balance);
					return;
				}

				if (Status === "INVALID_WEB_REF") {
					setCurBalance(response);
					setCurrentState(constStates.customErrorMessage);
					return;
				}

				// ** ERROR **
				setCurBalance(undefined);
				setCurrentState(constStates.balanceFailedUnmatchedRef);
			})
			.catch((err) => {
				setCurrentState(constStates.balanceFailedTechnicalError);
			});
	};

	console.log("DEBUG", "curCredentials", curCredentials);
	console.log("DEBUG", "curBalance", curBalance);
	console.log("DEBUG", "props", props);

	console.log("DEBUG", "defaultWebReference", defaultWebReference);

	const basePropsStart = (function () {
		let retValue = {
			onContinue: onValueChange,
			panelHeader: panelHeader
		};

		// defaultWebReference
		if (curCredentials && curCredentials.WebReference)
			retValue.defaultWebReference = curCredentials.WebReference;
		else if (defaultWebReference)
			retValue.defaultWebReference = defaultWebReference;

		// defaultPostcode
		if (curCredentials && curCredentials.Postcode)
			retValue.defaultPostcode = curCredentials.Postcode;

		// defaultDateOfBirth
		if (curCredentials && curCredentials.DateOfBirth)
			retValue.defaultDateOfBirth = curCredentials.DateOfBirth;

		return retValue;
	})();
	
	
	//******************************************** */
	//** MAIN return statements **
	//******************************************** */
	if (currentState === constStates.reset) return null;

	if (currentState === constStates.start)
		return (
			<>
				<IdleTimer timeout={idleTimeout} onIdle={() => resetPage()} />
				<PaymentLandingForm
					{...basePropsStart}
					errorMessage={errorMessage} //errorMessage is optional
				/>
				
				{config.isDev && (
					<div className="mt-3 d-flex gap-3">
						<Button
							className="btn-outline-info"
							onClick={() => {
								setCurrentState(constStates.balance);
								setCurCredentials(sampleCredentials);
								setCurBalance(sampleBalance);
							}}
						>
							Dev switch to BALANCE
						</Button>
						<Button
							className="btn-outline-info"
							onClick={() => {
								setCurrentState(constStates.balance);
								setCurCredentials(sampleCredentials);
								setCurBalance(sampleBalanceZero);
							}}
						>
							Dev switch to ZERO BALANCE
						</Button>
					</div>
				)}
			</>
		);

	if (currentState === constStates.balanceFailedUnmatchedRef)
		return (
			<>
				<IdleTimer timeout={idleTimeout} onIdle={() => resetPage()} />
				<PaymentLandingForm
					{...basePropsStart}
					errorMessage={errorMessageFailedLookup}
				/>
			</>
		);

	if (currentState === constStates.balanceFailedTechnicalError)
		return (
			<>
				<IdleTimer timeout={idleTimeout} onIdle={() => resetPage()} />
				<PaymentLandingForm
					{...basePropsStart}
					errorMessage="We're sorry, there has been a technical error. Please try again."
				/>
			</>
		);

	if (currentState === constStates.balanceLoading)
		return (
			<>
				<ModalLoadingOpened />
			</>
		);

	if (currentState === constStates.paymentLauncher)
		return (
			<>
				<ModalLoadingOpened />
			</>
		);

	if (currentState === constStates.customErrorMessage)
		return (
			<Layout
				breakpoint={layoutBreakpoint}
			>
				<IdleTimer timeout={idleTimeout} onIdle={() => resetPage()} />
				<PaymentLandingForm
					{...basePropsStart}
					errorMessage={curBalance.Message}
				/>
			</Layout>
		);

	if (currentState === constStates.balance)
		return (
			<Layout
				breakpoint={layoutBreakpoint}
				sidecontent={<PaymentStepChange />}
			>
				<IdleTimer timeout={idleTimeout} onIdle={() => resetPage()} />
				<PaymentLandingBalance
					panelHeader={panelHeader}
					balance={curBalance}
					credentials={curCredentials}
					fnGotoPayment={fnGotoPayment}
					// transmissionService={transmissionService}
					// fueltypeService={fueltypeService}
					lookups={lookups}
				/>
			</Layout>
		);

	return null;
};

export default PaymentLanding;
