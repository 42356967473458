import React from "react";
import siteServices from "siteManagers/services";

import PageLayout from "siteLayouts/PageV2";
import Layout from "siteLayouts/SidePanelRight";
import CallbackPanel from "siteLayouts/sidepanels/Callback";

import { ContainerMaxWidth, Panel, PanelSection } from "siteComponentsLibrary/Layout";
import { MakeAClaimDisplay, ListItemsFlex } from "siteComponentsLibrary/Display";
import { PolicyDocsLink } from "siteComponentsLibrary/Buttons";

const assets = require("siteAssets/pageManager").default("commercial-insurance");

const CommercialInsurance = (props) => {
	const breakpoint = "xl";

	return (
		<PageLayout pageTitle={assets.pageTitle}>
			
			<ContainerMaxWidth>
				<Layout
					breakpoint={breakpoint}
					sidecontent={
						<>
							<CallbackPanel
								defaultProduct={assets.callbackProducts}
								headertext="Request a callback"
								fnGetDates={siteServices.callback.getDates}
								fnSendRequest={siteServices.callback.sendRequest}
								fnGetProductTypeList={
									siteServices.callback.getProductTypeList
								}
							/>
							{assets.claimType && (
								<MakeAClaimDisplay claimType={assets.claimType} />
							)}
						</>
					}
				>
					<Panel
						header="Our commercial insurance products"
					>
						<PanelSection
							heading="Tradesman Insurance"
							classNameChildren="div-gap-3"
						>
							<div>
								Tradesman and Professionals insurance products are designed to meet the
								demands and needs of self-employed individuals and small businesses (including
								limited companies) undertaking a variety of manual, clerical and professional trades.
							</div>
							<div>
								<strong>Some key features of the policy cover:</strong>
							</div>
							<ListItemsFlex
								className=""
								bullet={<span className="bi bi-circle-fill"></span>}
								listData={
									[
										"Public and Products Liability limits of £1,000,000, £2,000,000 and £5,000,000",
										"Employers' Liability available with a standard £10,000,000 limit",
										"£10,000 of Accidental Death cover included as standard providing protection",
									]
								}
							/>
						</PanelSection>

						<PanelSection
							heading="Public Liability Insurance"
							classNameChildren="div-gap-3"
						>
							<div>
								This is designed to protect a business against claims in case any third parties are
								injured or their property is damaged as a result of business operations/business
								activities.
							</div>
							<div>
								<strong>Legal Liability for damages in respect of:</strong>
							</div>
							<ListItemsFlex
								className=""
								bullet={<span className="bi bi-circle-fill"></span>}
								listData={
									[
										"Accidental bodily injury to any person",
										"The registration number of your vehicle",
									]
								}
							/>
						</PanelSection>

						<PanelSection
							heading="Employers' Liability Insurance"
							classNameChildren="div-gap-3"
						>
							<div>
								This is designed even if a business only has one staff member. It provides
								protection for any legal responsibilities towards employees becoming injured as a
								result of their work.
							</div>
							<ListItemsFlex
								className=""
								bullet={<span className="bi bi-circle-fill"></span>}
								listData={
									[
										"Will pay all amounts which you become legally liable to pay as damages and costs and expenses for bodily injury to any employee",
										"Indemnity to other people",
										"Amounts which you become legally liable to pay for defence of any claim under the Health and Safety at Work Act 1974",
									]
								}
							/>
						</PanelSection>
						
						<PanelSection>
							<div className="alert alert-success small text-center">
								For full details on what is covered with our commercial insurance products, including
								the terms, conditions and exclusions, please read the{" "}
								<PolicyDocsLink
									label="Policy Wording"
									pdf={require("config").default.links.docs.policyWording.commercial}
								/>.
							</div>
						</PanelSection>
					</Panel>
				</Layout>
			</ContainerMaxWidth>
			
		</PageLayout>
	);
};

export default CommercialInsurance;
