import React from "react";

import logo from "siteAssets/images/logo.png";

import { SiteHeaderWithNav } from "siteComponentsLibrary/Layout";
import { ExistingCustomersButton, MakeAClaimButton } from "siteComponentsLibrary/Buttons";
import { HeaderImages } from "siteComponentsLibrary/Display";


const SiteHeaderTemplate = (props) => {
	
	const { goToRoute, headerImages } = props;
	
	return (
		<>
			<SiteHeaderWithNav
				brandImage={logo}
				brandImageAlt="Freedom Brokers"
				brandImageOnClick={() => goToRoute("/")}
				unwrap="lg"
				stickyClass="sticky-top"
				navbarId="siteHeaderNav"
				navbarStyle="light"
				linksMain={
					<>
						<li className="nav-item">
							<MakeAClaimButton />
						</li>
						<ExistingCustomersButton navItemClass="menu-float" />
					</>
				}
				goToRoute={goToRoute}
				navLinksData={
					[
						{ id: 'motor', route: "/motor-insurance", label: "Motor" },
						{ id: 'telematics', route: "/telematics", label: "Telematics" },
						{ id: 'gap', route: "/gap-insurance", label: "GAP" },
						{ id: 'commercial', route: "/commercial-insurance", label: "Commercial" },
						{ id: 'opex', route: "/optional-cover", label: "Optional Cover" }
					]
				}
				mobileText="For great value insurance"
			/>
			
			<HeaderImages data={headerImages} goToRoute={goToRoute} />			
		</>
		
	);

};

export default SiteHeaderTemplate;
