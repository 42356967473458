import React from "react";

import { ContainerMaxWidth, Panel, PanelSection, PanelHeader } from "siteComponentsLibrary/Layout";
import { ExternalLink, HyperlinkRoute } from "siteComponentsLibrary/Buttons";
import { Card, OpexSummary } from "siteComponentsLibrary/Cards";
import { TelephoneNumber, TwoStylePageTitle, PortalLinks } from "siteComponentsLibrary/Display";

import PageLayout from "siteLayouts/Page";
import Layout from "siteLayouts/SidePanelRight";


const OpexSidePanel = (props) => {
	const { data, sidePanelBreakpoint } = props;

	const portalLogin = require("config").default.links.portal.login;
	const portalRegister = require("config").default.links.portal.register;

	const priceText = data.priceText ? data.priceText : "Only";

	return (
		<div className="row">
			<div className={`col-12 col-md-6 col-${sidePanelBreakpoint}-12`}>				
				<Card
					className="panel has-thick-border mb-3"
					classNameHeader="px-3 px-sm-4"
					classNameBody="px-3 px-sm-4"
					classNameFooter="px-3 px-sm-4 border-top-0 bg-transparent pb-3"
					header={<div className="fs-5 fw-semibold">Add this to your policy</div>}
					footer={
						data.onlinePurchase && (
							<div className="">
								<PortalLinks 
									className="col-md-6"
									login={portalLogin} 
									register={portalRegister} 
								/>
							</div>
						)
					}
				>
					<div className="fs-4 mb-3">
						{priceText}{" "}
						<strong className="money-value">{data.annualPrice}</strong> per
						year
					</div>

					{"monthlyPrice" in data && (
						<div className="fs-5 mb-3">
							That's just{" "}
							<strong className="money-value">{data.monthlyPrice}</strong>{" "}
							per month
						</div>
					)}

					{data.offlinePurchase && (
						<div className="mb-3">
							To find out more or to add this to your existing policy,
							please call us on{" "}
							<strong className="whitespace-no-wrap">03332 205 442</strong>
						</div>
					)}

					{data.onlinePurchase && (
						<div className="">
							{data.offlinePurchase ? "Alternatively, log in" : "Log in"}{" "}
							to your online account to add this to your existing policy.
						</div>
					)}
				</Card>
			</div>

			<div className={`col-12 col-md-6 col-${sidePanelBreakpoint}-12`}>
				<Card
					className=""
					classNameHeader="text-center"
					classNameBody="gap-3"
					classNameFooter="text-center border-top-0 bg-transparent"
					header={<div className="fs-5 fw-semibold">Need to make a claim?</div>}
				>
					<p className="text-center">Simply call us on</p>

					{data.claimNumbers.length === 1 && (
						<p className="text-center fs-5">
							<TelephoneNumber phonenumber={data.claimNumbers[0]} isLink={true} />							
						</p>
					)}
					{data.claimNumbers.length > 1 && (
						<p className="text-center fs-5">
							<TelephoneNumber phonenumber={data.claimNumbers[0]} isLink={true} />
							{" "}or{" "}
							<TelephoneNumber phonenumber={data.claimNumbers[1]} isLink={true} />
						</p>
					)}

					{data.previousClaimNumbers && (
						<p className="small text-center alert alert-success">
							For policies purchased{" "}
							<strong>before {data.previousClaimNumbers.endDate}</strong>,
							please call{" "}
							{data.previousClaimNumbers.claimNumbers.length === 1 && (
								<TelephoneNumber phonenumber={data.previousClaimNumbers.claimNumbers[0]} isLink={false} />
							)}
							{data.previousClaimNumbers.claimNumbers.length > 1 && (
								<span>
									<TelephoneNumber phonenumber={data.previousClaimNumbers.claimNumbers[0]} isLink={false} />
									{" "}or{" "}
									<TelephoneNumber phonenumber={data.previousClaimNumbers.claimNumbers[1]} isLink={false} />
								</span>
							)}
						</p>
					)}

					{data.claimUrl && (
						<p className="text-center">
							Alternatively, you can{" "}
							<ExternalLink href={data.claimUrl} label="claim online" />.
						</p>
					)}

					{data.claimDetails && data.claimDetails}
				</Card>
			</div>
		
		</div>
	);
};

const OpexInfoPage = (props) => {
	const { assets } = props;

	const sidePanelBreakpoint = "xl";

	const data = require("siteAssets/datafiles/opexData").default(assets.opexId);

	if (assets.opexId === "ALL") {

		return (
			<PageLayout pageTitle={assets.pageTitle} headingText={assets.headingText}>
				<ContainerMaxWidth>

					<OpexSummary data={data} />
					
				</ContainerMaxWidth>
			</PageLayout>
		);
	}

	const policyDocsHeader = data.policyDocsHeader
		? data.policyDocsHeader
		: "Policy Documents";

	const pageTitle = assets.pageTitle ? assets.pageTitle : assets.headingText;
	const pageHeadingText = assets.usePageTitle ? (
		<TwoStylePageTitle
			icon={`opex-icon icon-opex-${data.groupCode}`}
			leftText={assets.headingText}
		/>
	) : null;
	
	const panelHeader = !assets.usePageTitle ? (
		<PanelHeader
			className="main-header"
			leftContent={assets.headingText}
			icon={`opex-icon icon-opex-${data.groupCode}`}
		/>
	) : null;

	return (
		<PageLayout pageTitle={pageTitle} headingText={pageHeadingText}>

			<ContainerMaxWidth>
				<Layout
					breakpoint={sidePanelBreakpoint}
					sidecontent={
						<OpexSidePanel
							data={data}
							sidePanelBreakpoint={sidePanelBreakpoint}
						/>
					}
				>
					<Panel header={panelHeader}>
						
						<PanelSection heading={data.pageHeading}>
							{data.description}
						</PanelSection>

						{data.documents && (
							<PanelSection heading={policyDocsHeader}>
								{data.documents.map((doc, i) => {
									if (!doc.endDate) {
										return (
											<div key={i}>
												<p>
													For further information, please refer to your{" "}
													<ExternalLink
														href={doc.ipidUrl}
														label="Insurance Product Information Document"
													/>
												</p>
												<p>
													For full details on what is covered with this service,
													including the terms, conditions and exclusions, please
													see the{" "}
													<ExternalLink
														href={doc.policyWordingUrl}
														label="Policy Wording"
													/>
												</p>
											</div>
										);
									}

									return (
										<div key={i}>
											<p>
												If your insurance policy{" "}
												{doc.endDateText ? doc.endDateText : "start date was"}{" "}
												<strong>before {doc.endDate}</strong>, your documents
												can be found below.
											</p>
											<ul>
												<li>
													<ExternalLink
														href={doc.ipidUrl}
														label="Insurance Product Information Document"
													/>
												</li>
												<li>
													<ExternalLink
														href={doc.policyWordingUrl}
														label="Policy Wording"
													/>
												</li>
											</ul>
										</div>
									);
								})}
							</PanelSection>
						)}
						
					</Panel>
					
				</Layout>
			</ContainerMaxWidth>
			
		</PageLayout>
	);
};

export default OpexInfoPage;
