import React from "react";

import PageLayout from "siteLayouts/Page";

import { ContainerMaxWidth, Panel, PanelSection } from "siteComponentsLibrary/Layout";
import { ExternalLink } from "siteComponentsLibrary/Buttons";

const assets = require("siteAssets/pageManager").default("cookie-policy");

const CookiePolicyView = (props) => {
	return (
		<PageLayout pageTitle={assets.pageTitle} headingText={assets.headingText}>
			
			<ContainerMaxWidth>
				<Panel header={assets.panelHeader}>
					<PanelSection heading="About cookies">
						<div>
							A cookie is a file containing an identifier (a string of letters and
							numbers) that is sent by a web server to a web browser and is stored
							by the browser. The identifier is then sent back to the server each
							time the browser requests a page from the server.
						</div>
						<div>
							Cookies may be either “persistent” cookies or “session” cookies: a
							persistent cookie will be stored by a web browser and will remain
							valid until its set expiry date, unless deleted by the user before
							the expiry date; a session cookie, on the other hand, will expire at
							the end of the user session, when the web browser is closed.
						</div>
						<div>
							Cookies do not typically contain any information that personally
							identifies a user, but personal information that we store about you
							may be linked to the information stored in and obtained from
							cookies.
						</div>
					</PanelSection>

					<PanelSection
						heading="Cookies that we use"
					>
						<div>We use cookies for the following purposes:</div>
						<div>
							(a) authentication – we use cookies to identify you when you visit
							our website and as you navigate our website;
						</div>
						<div>
							(b) status – we use cookies to help us to determine if you are
							logged into our website;
						</div>
						<div>
							(c) personalisation – we use cookies to store information about your
							preferences and to personalise our website for you;
						</div>
						<div>
							(d) security – we use cookies as an element of the security measures
							used to protect user accounts, including preventing fraudulent use
							of login credentials, and to protect our website and services
							generally;
						</div>
						<div>
							(e) advertising – we use cookies to help us to display
							advertisements that will be relevant to you;
						</div>
						<div>
							(f) analysis – we use cookies to help us to analyse the use and
							performance of our website and services; and
						</div>
						<div>
							(g) cookie consent – we use cookies to store your preferences in
							relation to the use of cookies more generally.
						</div>
					</PanelSection>

					<PanelSection
						heading="Cookies used by our service providers"
					>
						<div>
							Our service providers use cookies and those cookies may be stored on
							your computer when you visit our website.
						</div>
						<div>
							We use Google Analytics to analyse the use of our website. Google
							Analytics gathers information about website use by means of cookies.
							The information gathered relating to our website is used to create
							reports about the use of our website. Google’s privacy policy is
							available at:{" "}
							<ExternalLink
								href="https://www.google.com/policies/privacy/"
								label="https://www.google.com/policies/privacy/"
								nowrap={false}
							/>.
						</div>
					</PanelSection>

					<PanelSection
						heading="Managing cookies"
					>
						<div>
							Most browsers allow you to refuse to accept cookies and to delete
							cookies. The methods for doing so vary from browser to browser, and
							from version to version. You can however obtain up-to-date
							information about blocking and deleting cookies via these links:
						</div>
						<div>
							(a){" "}
							<ExternalLink
								href="https://support.google.com/chrome/answer/95647?hl=en"
								label="https://support.google.com/chrome/answer/95647?hl=en (Chrome)"
								nowrap={false}
							/>
							;
						</div>
						<div>
							(b){" "}
							<ExternalLink
								href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
								label="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences (Firefox)"
								nowrap={false}
							/>
							;
						</div>
						<div>
							(c){" "}
							<ExternalLink
								href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies"
								label="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies (Internet Explorer)"
								nowrap={false}
							/>
							;
						</div>
						<div>
							(d){" "}
							<ExternalLink
								href="https://support.apple.com/kb/PH21411"
								label="https://support.apple.com/kb/PH21411 (Safari)"
								nowrap={false}
							/>
							; and
						</div>
						<div>
							(e){" "}
							<ExternalLink
								href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy"
								label="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy (Edge)"
								nowrap={false}
							/>
							.
						</div>
						<div>
							Blocking all cookies will have a negative impact upon the usability
							of many websites.
						</div>
						<div>
							If you block cookies, you will not be able to use all the features
							on our website.
						</div>
					</PanelSection>
				</Panel>
			</ContainerMaxWidth>

		</PageLayout>
	);
};

export default CookiePolicyView;
